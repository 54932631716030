import { primaryAuth } from "config/firebase";
import { signOut } from "firebase/auth";

export default function signOutUser() {
   
  
  signOut(primaryAuth)
    .then(() => {
      console.log("Sign-out successful.");
      
    })
    .catch((error) => {
      console.error("An error happened during sign-out:", error);
    });
}
